var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-1 ma-1", attrs: { tile: "", color: "grey lighten-3" } },
    [
      _c("v-card-title", [_vm._v("おねじの形状を選ぶ")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("card-button", {
                    staticClass: "mb-6",
                    attrs: {
                      headerIsOn: true,
                      headerTitle: "頭部の形状",
                      inputItems: _vm.bolt_icons.head,
                      labelIsOn: true,
                    },
                    on: { "update-query": _vm.makeQuery },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-slide-y-transition",
                    [
                      _vm.isPicked.head
                        ? _c("carousel-button", {
                            staticClass: "mb-6",
                            attrs: {
                              headerIsOn: true,
                              headerTitle: "おねじ先端の形状",
                              inputItems: _vm.selectableTip,
                            },
                            on: { "update-query": _vm.makeQuery },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-slide-y-transition",
                    [
                      _vm.isPicked.tip
                        ? _c("carousel-button", {
                            staticClass: "mb-6",
                            attrs: {
                              headerIsOn: true,
                              headerTitle: "頭部穴の形状",
                              inputItems: _vm.selectableHoleShape,
                            },
                            on: { "update-query": _vm.makeQuery },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "pt-3" }),
          _c("page-transition-button", {
            attrs: { nextIsNecessary: false },
            on: { "click-back": _vm.backToPreviousPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }